/* eslint-disable */
// prettier-ignore
export const lowercase = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
// prettier-ignore
export const uppercase = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
// prettier-ignore
export const numbers = ['1','2','3','4','5','6','7','8','9','0'];
// prettier-ignore
export const special = ['~','`','!','@','#','$','%','^','&','*','(',')','_','-','+','=','{','[','}',']','|','\\',':',';','\"','\'','<',',','>','.','?','/'];
/* eslint-enable */
export const all = [...lowercase, ...uppercase, ...numbers, ...special];

export const benchPassword = 'abcd';

export const tryAllPasswords = () => {
  let passwordFound = false;
  let passwordsTried = 0;
  let index = 0;
  const testArr: string[] = [];

  function travel(depth: number, maxLength: number) {
    if (depth > maxLength) return;
    if (passwordFound) return;
    all.forEach((l) => {
      if (passwordFound) return;
      testArr.push(l);

      if (benchPassword === testArr.join('')) {
        passwordFound = true;
        passwordsTried++;
        return;
      }
      travel(depth + 1, maxLength);
      testArr.pop();
      passwordsTried++;
    });
  }

  while (index < benchPassword.length && !passwordFound) {
    travel(0, index);
    index++;
  }
  return passwordsTried;
};
