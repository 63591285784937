import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import Password from './Password/Password';
import Rope from './Rope/Rope';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/rope" element={<Rope />} />
          <Route path="/pw" element={<Password />} />
          <Route path="/" element={<div>:)</div>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
